<template lang="pug">
.manage-payment-method

  template(v-if="payment_methods.length <= 0")
    .d-flex.justify-center
      v-img.mt-12(
        :src="require('../../assets/img/undraw/undraw_selection_re_ycpo.svg')"
        max-width="300"
      )

  v-card.card-simple.rounded-xl.mb-4(
    v-else
    v-for="(payment_method) in payment_methods"
    :key="`payment-method-${payment_method.id}`"
  )
    v-list(color="transparent")
      v-list-item(color="secondary")
        v-list-item-avatar.mr-3.my-0
          v-icon(
            color="#80dbc1"
            large
          ) mdi-credit-card
        v-list-item-content.py-0
          v-list-item-title
            .d-inline.mr-2 {{ formatTitle(payment_method) }}
            v-chip.px-2(
              v-if="isDefaultPaymentMethod(payment_method)"
              color="#5a3de6"
              dark x-small
            ) Primær
          v-list-item-subtitle
            .d-inline.mr-2 {{ formatSubtitle(payment_method) }}

        v-list-item-action.ma-0(
          v-if="!isDefaultPaymentMethod(payment_method)"
        )
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Bytt primær betalingsmåte
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                @click="doSetDefaultPaymentMethod(payment_method.id)"
                icon
              )
                v-icon mdi-swap-horizontal
        v-list-item-action.ma-0
          v-tooltip(
            transition="drop-front"
            color="black"
            bottom
          )
            span Fjern betalingsmåte
            template(#activator="{ on, attrs }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                @click="doDetachPaymentMethod(payment_method.id)"
                icon
              )
                v-icon mdi-close
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ManagePaymentMethod',
  data: () => ({
    loading: false
  }),
  computed: mapState('Stripe', ['customer', 'payment_methods']),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Stripe', [
      'listPaymentMethod',
      'updateCustomer',
      'detachPaymentMethod'
    ]),
    isDefaultPaymentMethod(payment_method) {
      return (
        get(payment_method, 'id', true) ===
        get(this.customer, 'invoice_settings.default_payment_method', false)
      )
    },
    formatTitle(payment_method) {
      const brand = get(payment_method, 'card.brand', 'ukjent')
      const brandCapitalized = brand.charAt(0).toUpperCase() + brand.slice(1)

      return `${brandCapitalized} •••• ${get(
        payment_method,
        'card.last4',
        'XXX'
      )}`
    },
    formatSubtitle(payment_method) {
      return `${get(payment_method, 'billing_details.name', '')}, utløper ${get(
        payment_method,
        'card.exp_month',
        'XX'
      )} / ${get(payment_method, 'card.exp_year', 'XXXX')}`
    },
    async doSetDefaultPaymentMethod(id) {
      this.loading = true
      try {
        await this.updateCustomer({
          invoice_settings: { default_payment_method: id }
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    },
    doDetachPaymentMethod(id) {
      if (
        confirm('Er du sikker på at du ønsker å slette denne betalingsmåten?')
      )
        this.detachPaymentMethod(id)
    }
  },
  async mounted() {
    try {
      await this.listPaymentMethod()
    } catch (e) {
      // NOP
    }
  }
}
</script>

<style lang="stylus" scoped></style>
