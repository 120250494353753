<template lang="pug">
.billing-payment-method

  template(v-if="!customer")
    .text-center.py-16
      .mb-2 Vennligst oppgi kundeinformasjon først.
      v-btn(
        to="/billing"
        color="secondary"
        plain depressed rounded
      )
        v-icon(left small) mdi-chevron-left
        | Gå til kundeinformasjon
  template(v-else)
    create-payment-method.mb-8
    manage-payment-method
</template>

<script>
import { mapState } from 'vuex'
import { CreatePaymentMethod, ManagePaymentMethod } from '@/components/billing'

export default {
  name: 'BillingPaymentMethod',
  components: {
    CreatePaymentMethod,
    ManagePaymentMethod
  },
  computed: mapState('Stripe', ['customer'])
}
</script>

<style lang="stylus" scoped></style>
