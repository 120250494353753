<template lang="pug">
v-card.card-simple.rounded-xl
  .text-h5.font-weight-bold.primary--text.pa-8.pb-0 Kundeinformasjon
  v-card-text.pa-8
    v-form(
      v-model="form"
      @submit.prevent
    )
      v-autocomplete(
        :menu-props="menuProps()"
        :disabled="loading"
        :rules="[rules.required('Oppgi gyldig navn')]"
        :search-input.sync="company_name_input"
        :items="company_name_list"
        v-model="company_name_value"
        item-text="navn"
        item-value="navn"
        label="Organisasjonsnavn"
        append-icon="mdi-briefcase"
        ref="company_name"
        return-object
        rounded filled autofocus
      )
      v-subheader
        v-icon.mr-2(
          color="primary"
          left
        ) mdi-information
        | Vi henter organisasjonsnummeret automatisk for deg:
      v-text-field(
        :disabled="loading"
        v-model="metadata.organization_number"
        label="Organisasjonsnummer"
        append-icon="mdi-counter"
        rounded filled readonly
      )
      v-subheader
        v-icon.mr-2(
          color="primary"
          left
        ) mdi-information
        | Vi sender kvitteringer og varslinger om betalinger til denne eposten:
      v-text-field(
        :disabled="loading"
        :rules="[rules.required('Oppgi en epost'), rules.validEmail]"
        v-model="email"
        label="Epost"
        type="email"
        append-icon="mdi-email"
        ref="email"
        rounded filled
      )
      v-subheader
        v-icon.mr-2(
          color="primary"
          left
        ) mdi-information
        | Organisasjonens postadresse (valgfritt, vises på faktura):
      v-row.mb-4
        v-col.mb-2(cols="12" md="6")
          v-text-field(
            :disabled="loading"
            v-model="shipping.address.line1"
            label="Adresse 1"
            append-icon="mdi-road-variant"
            rounded filled hide-details
          )
        v-col.mb-2(cols="12" md="6")
          v-text-field(
            :disabled="loading"
            v-model="shipping.address.line2"
            label="Adresse 2"
            append-icon="mdi-road-variant"
            rounded filled hide-details
          )
        v-col.mb-2(cols="12" md="6")
          v-text-field(
            :disabled="loading"
            v-model="shipping.address.postal_code"
            label="Postkode"
            append-icon="mdi-mailbox"
            rounded filled hide-details
          )
        v-col.mb-2(cols="12" md="6")
          v-text-field(
            :disabled="loading"
            v-model="shipping.address.city"
            label="By"
            append-icon="mdi-city"
            rounded filled hide-details
          )
    v-btn(
      :disabled="!form || loading"
      :loading="loading"
      @click="submit"
      color="secondary"
      x-large block depressed rounded
    ) Lagre
</template>

<script>
import { debounce, get } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { menuProps, rules } from '@/mixins'
import { cloneDeep } from '@/lib/utils'

const BRREG_API_ENDPOINT = 'https://data.brreg.no/enhetsregisteret/api/enheter'

export default {
  name: 'ManageCustomer',
  mixins: [menuProps, rules],
  data: () => ({
    loading: false,
    loading_search: false,
    form: false,

    company_name_input: null,
    company_name_value: null,
    company_name_list: [],

    metadata: {
      organization_number: null
    },
    name: null,
    email: null,
    shipping: {
      name: null,
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null
      }
    }
  }),
  computed: {
    ...mapState('Company', ['company']),
    ...mapState('Stripe', ['customer'])
  },
  watch: {
    customer: {
      immediate: true,
      handler(v) {
        if (v) {
          this.metadata = cloneDeep(v.metadata)
          this.email = cloneDeep(v.email)
          this.shipping = cloneDeep(v.shipping)

          // Needed for initial autocomplete value
          this.company_name_list = [
            {
              navn: get(this.shipping, 'name', null),
              organisasjonsnummer: get(
                this.metadata,
                'organization_number',
                null
              )
            }
          ]
          this.company_name_value = this.shipping.name
        }

        // Override immutables
        this.name = cloneDeep(this.company.name)
        this.shipping.address.country = 'NO'
      }
    },
    company_name_input: debounce(async function (val) {
      if (val === get(this.company_name_value, 'navn', null)) return

      this.loading_search = true
      try {
        const result = await fetch(
          `${BRREG_API_ENDPOINT}?navn=${encodeURIComponent(val)}`
        )
        const json = await result.json()
        this.company_name_list = json._embedded.enheter
      } catch (e) {
        this.company_name_list = []
      } finally {
        this.loading_search = false
        this.$nextTick(() => {
          if (this.$refs.company_name) this.$refs.company_name.focus()
        })
      }
    }, 250),
    company_name_value(val) {
      this.shipping.name = get(val, 'navn', null)
      this.metadata.organization_number = get(val, 'organisasjonsnummer', null)

      // Convenience, fill out company address if no info exists (and we have info)
      if (
        !this.shipping.address.line1 &&
        !this.shipping.address.line2 &&
        !this.shipping.address.postal_code &&
        !this.shipping.address.city
      ) {
        this.shipping.address.line1 = get(
          val,
          'forretningsadresse.adresse[0]',
          null
        )
        this.shipping.address.line2 = get(
          val,
          'forretningsadresse.adresse[1]',
          null
        )
        this.shipping.address.postal_code = get(
          val,
          'forretningsadresse.postnummer',
          null
        )
        this.shipping.address.city = get(
          val,
          'forretningsadresse.poststed',
          null
        )
      }

      this.$refs.email.focus()
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Stripe', ['updateCustomer']),
    async submit() {
      if (!this.form) return

      this.loading = true
      try {
        await this.updateCustomer({
          metadata: this.metadata,
          name: this.name,
          email: this.email,
          address: this.shipping.address, // same as shipping
          shipping: this.shipping
        })
        this.setSnackbar({
          type: 'success',
          msg: 'Informasjonen ble lagret'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
