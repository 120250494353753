<template lang="pug">
.billing-invoice

  template(v-if="!customer")
    .text-center.py-16
      .mb-2 Vennligst oppgi kundeinformasjon først.
      v-btn(
        to="/billing"
        color="secondary"
        plain depressed rounded
      )
        v-icon(left small) mdi-chevron-left
        | Gå til kundeinformasjon
  manage-invoice(v-else)
</template>

<script>
import { mapState } from 'vuex'
import { ManageInvoice } from '@/components/billing'

export default {
  name: 'BillingInvoice',
  components: {
    ManageInvoice
  },
  computed: mapState('Stripe', ['customer'])
}
</script>

<style lang="stylus" scoped></style>
