<template lang="pug">
.manage-subscription

  v-card.card-simple.rounded-xl
    
    .text-h5.font-weight-bold.primary--text.pa-8.pb-0 Abonnement
    v-card-text.pa-8

      //- Products
      .mb-8
        .text-h6.font-weight-normal.primary--text
          v-icon.mb-1(color="primary" left) mdi-numeric-1-circle
          | Velg produkt
        v-divider.mt-2.pb-6

        v-item-group(
          v-model="selected_product"
          mandatory
        )
          v-row
            v-col(cols="12" md="3")
              v-item(
                v-slot="{ active, toggle }"
                :value="null"
              )
                v-card.d-flex.align-center.rounded-xl.py-2.px-4(
                  @click="toggle"
                  :color="active ? 'whey' : 'background'"
                )
                  .flex-grow-1.text-center
                    .text-h6.font-weight-black Ingen valg
            v-col(
              v-for="(product) in products"
              :key="`product-${product.id}`"
              cols="12"
              md="3"
            )
              v-item(
                v-slot="{ active, toggle }"
                :value="product.id"
              )
                v-card.d-flex.align-center.rounded-xl.py-2.px-4(
                  @click="toggle"
                  :color="active ? 'darksalad' : 'background'"
                )
                  .flex-grow-1.text-center
                    .text-h6.font-weight-black.pt-2.pb-4 {{ product.name }}
                    template(
                      v-for="(feature, index) in getProductFeatureList(product)"
                    )
                      .text-caption.my-2 {{ feature }}
                      v-divider(
                        v-if="index < getProductFeatureList(product).length - 1"
                      )

      //- Prices
      .mb-8(v-if="prices")
        .text-h6.font-weight-normal.primary--text
          v-icon.mb-1(color="primary" left) mdi-numeric-2-circle
          | Velg størrelse
        v-divider.mt-2.pb-6

        v-item-group(
          v-model="selected_price"
          mandatory
        )
          v-row
            v-col(
              v-for="(price) in prices"
              :key="`price-${price.id}`"
              cols="12"
              md="3"
            )
              v-item(
                v-slot="{ active, toggle }"
                :value="price.id"
              )
                v-card.d-flex.align-center.rounded-xl.py-2.px-4(
                  @click="toggle"
                  :color="active ? 'darksalad' : 'background'"
                )
                  .flex-grow-1.text-center
                    .text-h6.pt-2
                      v-icon.mb-1(
                        v-if="price.metadata.icon"
                        left
                      ) {{ price.metadata.icon }}
                      | {{ price.nickname }}
                    .text-h5.font-weight-bold.py-4
                      | {{ price.unit_amount / 100 }}
                      .text-subtitle-1.d-inline &nbsp;/ mnd
                    template(
                      v-for="(feature, index) in getPriceFeatureList(price)"
                    )
                      .text-caption.my-2 {{ feature }}
                      v-divider(
                        v-if="index < getPriceFeatureList(price).length - 1"
                      )
                    template(v-if="subscriptions.length <= 0")
                      v-divider
                      v-chip(
                        color="secondary"
                      ).mt-4.mb-2 Første måned gratis!

      v-btn(
        :disabled="!price_is_dirty || loading"
        :loading="loading"
        @click="submit"
        color="secondary"
        x-large block depressed rounded
      ) Lagre
</template>

<script>
import { get, isEqual } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { Stripe } from '@/service'

export default {
  name: 'ManageSubscription',
  data: () => ({
    loading: false,
    selected_product: null,
    selected_price: null,
    initial_price: null
  }),
  computed: {
    ...mapState('Stripe', ['products', 'subscriptions']),
    price_is_dirty() {
      return !isEqual(this.initial_price, this.selected_price)
    },
    prices() {
      const index = this.products.findIndex(
        ({ id }) => id === this.selected_product
      )
      return index < 0 ? null : this.products[index].prices
    },
    items() {
      return this.selected_price ? [this.selected_price] : []
    }
  },
  watch: {
    selected_product(v) {
      this.selected_price = v ? get(this.prices, '[0].id', null) : null
    },
    subscriptions: {
      immediate: true,
      handler(v) {
        if (v.length <= 0) return

        const item = get(v, '[0].items.data[0]', null)
        if (!item) return

        // Initialize from subscription
        this.selected_product = get(item, 'price.product', null)
        this.selected_price = get(item, 'price.id', null)
        this.initial_price = get(item, 'price.id', null)
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Company', ['readCompany']),
    ...mapActions('Stripe', [
      'listProduct',
      'updateSubscription',
      'listSubscription'
    ]),
    getProductFeatureList(product) {
      return get(product, 'description', '').split(',')
    },
    getPriceFeatureList(price) {
      return get(price, 'metadata.feature_list', '').split(',')
    },
    async submit() {
      this.loading = true
      try {
        const subscription = await this.updateSubscription({
          items: this.items
        })

        if (subscription !== '') {
          // Check payment intent
          const payment_intent = get(
            subscription,
            'latest_invoice.payment_intent',
            null
          )
          if (payment_intent) {
            const { client_secret, status } = payment_intent

            // Not OK
            if (
              status === 'requires_action' ||
              status === 'requires_payment_method'
            ) {
              const result = await Stripe.confirmCardPayment(client_secret)

              if (result.error) {
                throw new Error(result.error.message)
              }

              // Refetched subscription below will confirm the subscription
            }
          }

          // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
          this.$gtag.event('purchase', {
            currency: get(subscription, 'currency', 'nok'),
            transaction_id: get(subscription, 'id', null),
            value: get(subscription, 'plan.amount', 0) / 100,
            items: get(subscription, 'items.data', []).map((item) => ({
              item_id: get(item, 'id', null),
              item_name: get(item, 'price.nickname', null),
              currency: get(item, 'price.currency', 'nok'),
              price: get(item, 'price.unit_amount', 0) / 100,
              quantity: get(item, 'quantity', 1)
            }))
          })

          this.initial_price = this.selected_price
        } else {
          this.selected_product = null
        }

        // Refetch company so that feature object and other settings will be updated.
        // Refetch subscription so that deleted will be removed.
        // This will also confirm a new subscription that required action.
        // Timeout because webhook needs time to process
        this.setSnackbar({
          type: 'success',
          msg: 'Abonnementet ble oppdatert'
        })

        setTimeout(async () => {
          await Promise.all([this.readCompany(), this.listSubscription()])
        }, 1500)
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    try {
      await this.listProduct()
    } catch (e) {
      // NOP
    }
  }
}
</script>

<style lang="stylus" scoped>
.manage-subscription

  .close-form
    top 26px
    right 26px
    position absolute

  .v-card.d-flex
    height 100%
    box-shadow none !important

    &::before
      display none !important

    .v-icon
      color rgba(0, 0, 0, 0.87)

    &.v-item--active
      &.darksalad
        color #1a1e1d !important

      .v-icon
        color #1a1e1d !important

  .price-calculator
    color rgba(0, 0, 0, 0.6)

    .d-flex
      padding 8px 0
      border-bottom 1px solid rgba(0, 0, 0, 0.12)

      &:last-child
        padding-bottom 0
        border-bottom 0
</style>
