var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-subscription"},[_c('v-card',{staticClass:"card-simple rounded-xl"},[_c('div',{staticClass:"text-h5 font-weight-bold primary--text pa-8 pb-0"},[_vm._v("Abonnement")]),_c('v-card-text',{staticClass:"pa-8"},[_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"text-h6 font-weight-normal primary--text"},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"primary","left":""}},[_vm._v("mdi-numeric-1-circle")]),_vm._v("Velg produkt")],1),_c('v-divider',{staticClass:"mt-2 pb-6"}),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected_product),callback:function ($$v) {_vm.selected_product=$$v},expression:"selected_product"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-item',{attrs:{"value":null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-xl py-2 px-4",attrs:{"color":active ? 'whey' : 'background'},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('div',{staticClass:"text-h6 font-weight-black"},[_vm._v("Ingen valg")])])])]}}])})],1),_vm._l((_vm.products),function(product){return _c('v-col',{key:("product-" + (product.id)),attrs:{"cols":"12","md":"3"}},[_c('v-item',{attrs:{"value":product.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-xl py-2 px-4",attrs:{"color":active ? 'darksalad' : 'background'},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('div',{staticClass:"text-h6 font-weight-black pt-2 pb-4"},[_vm._v(_vm._s(product.name))]),_vm._l((_vm.getProductFeatureList(product)),function(feature,index){return [_c('div',{staticClass:"text-caption my-2"},[_vm._v(_vm._s(feature))]),(index < _vm.getProductFeatureList(product).length - 1)?_c('v-divider'):_vm._e()]})],2)])]}}],null,true)})],1)})],2)],1)],1),(_vm.prices)?_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"text-h6 font-weight-normal primary--text"},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"primary","left":""}},[_vm._v("mdi-numeric-2-circle")]),_vm._v("Velg størrelse")],1),_c('v-divider',{staticClass:"mt-2 pb-6"}),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selected_price),callback:function ($$v) {_vm.selected_price=$$v},expression:"selected_price"}},[_c('v-row',_vm._l((_vm.prices),function(price){return _c('v-col',{key:("price-" + (price.id)),attrs:{"cols":"12","md":"3"}},[_c('v-item',{attrs:{"value":price.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center rounded-xl py-2 px-4",attrs:{"color":active ? 'darksalad' : 'background'},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('div',{staticClass:"text-h6 pt-2"},[(price.metadata.icon)?_c('v-icon',{staticClass:"mb-1",attrs:{"left":""}},[_vm._v(_vm._s(price.metadata.icon))]):_vm._e(),_vm._v(_vm._s(price.nickname))],1),_c('div',{staticClass:"text-h5 font-weight-bold py-4"},[_vm._v(_vm._s(price.unit_amount / 100)),_c('div',{staticClass:"text-subtitle-1 d-inline"},[_vm._v(" / mnd")])]),_vm._l((_vm.getPriceFeatureList(price)),function(feature,index){return [_c('div',{staticClass:"text-caption my-2"},[_vm._v(_vm._s(feature))]),(index < _vm.getPriceFeatureList(price).length - 1)?_c('v-divider'):_vm._e()]}),(_vm.subscriptions.length <= 0)?[_c('v-divider'),_c('v-chip',{staticClass:"mt-4 mb-2",attrs:{"color":"secondary"}},[_vm._v("Første måned gratis!")])]:_vm._e()],2)])]}}],null,true)})],1)}),1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.price_is_dirty || _vm.loading,"loading":_vm.loading,"color":"secondary","x-large":"","block":"","depressed":"","rounded":""},on:{"click":_vm.submit}},[_vm._v("Lagre")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }