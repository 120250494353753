<template lang="pug">
.billing-subscription
  
  template(v-if="!customer")
    .text-center.py-16
      .mb-2 Vennligst oppgi kundeinformasjon først.
      v-btn(
        to="/billing"
        color="secondary"
        plain depressed rounded
      )
        v-icon(left small) mdi-chevron-left
        | Gå til kundeinformasjon
  template(v-else-if="payment_method_required")
    .text-center.py-16
      .mb-2 Vennligst oppgi betalingsmåte først.
      v-btn(
        to="/billing/payment_method"
        color="secondary"
        plain depressed rounded
      )
        v-icon(left small) mdi-chevron-left
        | Gå til betalingsmåter
  manage-subscription(v-else)
</template>

<script>
import { get } from 'lodash'
import { mapState } from 'vuex'
import { ManageSubscription } from '@/components/billing'

export default {
  name: 'BillingSubscription',
  components: {
    ManageSubscription
  },
  computed: {
    ...mapState('Stripe', ['customer', 'payment_methods', 'subscriptions']),
    // No payment method && no subscription == require payment method
    payment_method_required() {
      const items = get(this.subscriptions, '[0].items.data', [])
      return this.payment_methods.length <= 0 && items.length <= 0
    }
  }
}
</script>

<style lang="stylus" scoped></style>
