<template lang="pug">
.create-payment-method

  .d-flex(v-if="!show_form")
    v-spacer
    v-btn(
      @click="show_form = true"
      color="secondary"
      x-large depressed rounded
    )
      v-icon(left) mdi-plus
      | Legg til

  v-card.card-simple.rounded-xl(v-else)
    v-btn.close-form(
      @click="show_form = false"
      icon large depressed rounded
    )
      v-icon(color="secondary") mdi-close
    
    .text-h5.font-weight-bold.primary--text.pa-8.pb-0 Legg til betalingsmåte
    v-card-text.pa-8
      v-form.mb-9(
        v-model="form"
        @submit.prevent
        ref="form"
      )
        v-text-field(
          :disabled="loading"
          :rules="[rules.required('Oppgi ditt fulle navn')]"
          v-model="billing_details.name"
          label="Navn på kortholder"
          append-icon="mdi-account"
          rounded filled autofocus
        )
        v-checkbox.mt-0.mb-8(
          :disabled="loading"
          v-model="use_shipping_address"
          color="secondary"
          label="Bruk organisasjonsadresse"
          hide-details
        )
        v-row.mb-4(v-if="!use_shipping_address")
          v-col.mb-2(cols="12" md="6")
            v-text-field(
              :disabled="loading"
              v-model="billing_details.address.line1"
              label="Adresse 1"
              append-icon="mdi-road-variant"
              rounded filled hide-details
            )
          v-col.mb-2(cols="12" md="6")
            v-text-field(
              :disabled="loading"
              v-model="billing_details.address.line2"
              label="Adresse 2"
              append-icon="mdi-road-variant"
              rounded filled hide-details
            )
          v-col.mb-2(cols="12" md="6")
            v-text-field(
              :disabled="loading"
              v-model="billing_details.address.postal_code"
              label="Postkode"
              append-icon="mdi-mailbox"
              rounded filled hide-details
            )
          v-col.mb-2(cols="12" md="6")
            v-text-field(
              :disabled="loading"
              v-model="billing_details.address.city"
              label="By"
              append-icon="mdi-city"
              rounded filled hide-details
            )
        .stripe-card(ref="card")
        .stripe-error {{ card_error }}
      v-btn(
        :disabled="!form_valid || loading"
        :loading="loading"
        @click="submit"
        color="secondary"
        x-large block depressed rounded
      ) Lagre
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { rules } from '@/mixins'
import { Stripe } from '@/service'
import { cloneDeep } from '@/lib/utils'

const elements = Stripe.elements()
const card_options = {
  hidePostalCode: true,
  style: {
    base: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: '"Manrope", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.6)'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

export default {
  name: 'CreatePaymentMethod',
  mixins: [rules],
  data: () => ({
    loading: false,
    show_form: false,
    form: false,
    card: null,
    card_error: null,
    card_complete: false,
    use_shipping_address: true,

    billing_details: {
      name: null,
      email: null,
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null
      }
    }
  }),
  computed: {
    ...mapState('Stripe', ['customer']),
    form_valid() {
      return this.form && this.card_complete
    }
  },
  watch: {
    customer: {
      immediate: true,
      handler(v) {
        if (v) {
          this.billing_details.email = cloneDeep(v.email)
        }

        // Override immutables
        this.billing_details.address.country = 'NO'
      }
    },
    show_form(v) {
      if (v) {
        this.use_shipping_address = true
        this.$nextTick(() => {
          this.card = elements.create('card', card_options)
          this.card.mount(this.$refs.card)
          this.card.on('change', ({ complete, error }) => {
            this.card_complete = complete
            this.card_error = error ? error.message : null
          })
        })
      } else {
        if (this.card) this.card.destroy()
        this.card = null

        this.$refs.form.reset()
      }
    },
    use_shipping_address: {
      immediate: true,
      handler(v) {
        if (!v) return

        this.billing_details.address.city = cloneDeep(
          get(this.customer, 'shipping.address.city')
        )
        this.billing_details.address.line1 = cloneDeep(
          get(this.customer, 'shipping.address.line1')
        )
        this.billing_details.address.line2 = cloneDeep(
          get(this.customer, 'shipping.address.line2')
        )
        this.billing_details.address.postal_code = cloneDeep(
          get(this.customer, 'shipping.address.postal_code')
        )
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Stripe', ['attachPaymentMethod']),
    async submit() {
      if (!this.form_valid) return

      this.loading = true
      try {
        // Create payment method based on card details and email
        const { paymentMethod, error } = await Stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: this.billing_details
        })

        // Abort if something failed
        if (error) throw error

        await this.attachPaymentMethod({ id: paymentMethod.id })

        this.show_form = false
        this.setSnackbar({
          type: 'success',
          msg: 'Betalingsmåten ble lagt til'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    if (this.card) this.card.destroy()
  }
}
</script>

<style lang="stylus" scoped>
.create-payment-method

  .close-form
    top 26px
    right 26px
    position absolute

  .stripe-card
    padding 18px 24px
    background rgba(0, 0, 0, 0.06)
    border-radius 28px

  .stripe-error
    padding 0 12px
    margin 8px 0
    line-height 12px
    font-size 12px
    color var(--v-error-base) !important
    position absolute
</style>
