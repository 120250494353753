<template lang="pug">
.billing-customer
  manage-customer
</template>

<script>
import { ManageCustomer } from '@/components/billing'

export default {
  name: 'BillingCustomer',
  components: {
    ManageCustomer
  }
}
</script>

<style lang="stylus" scoped></style>
