<template lang="pug">
.manage-invoice

  v-skeleton-loader(
    v-if="loading"
    type="paragraph"
  )
  template(v-else)
    template(v-if="invoices.length <= 0")
      .d-flex.justify-center
        v-img.mt-12(
          :src="require('../../assets/img/undraw/undraw_selection_re_ycpo.svg')"
          max-width="300"
        )

    template(v-else)
      v-expansion-panels.expansion-panels-simple
        v-expansion-panel.rounded-xl.mb-4(
          v-for="(invoice) in invoices"
          :key="`invoice-${invoice.id}`"
        )
          v-expansion-panel-header
            v-list(color="transparent")
              v-list-item(color="secondary")
                v-list-item-avatar.mr-3.my-0
                  v-icon(
                    color="#80dbc1"
                    large
                  ) mdi-receipt
                v-list-item-content.py-0
                  v-list-item-title
                    .d-inline.mr-2(v-html="formatTitle(invoice)")
                    v-chip.px-2(
                      :color="isPaid(invoice) ? '#5a3de6' : 'red'"
                      dark x-small
                    ) {{ isPaid(invoice) ? 'Betalt' : 'Ikke betalt'}}
                  v-list-item-subtitle
                    .d-inline.mr-2 {{ formatSubtitle(invoice) }}
          v-expansion-panel-content
            v-simple-table(dense)
              template(#default)
                tbody
                  tr(
                    v-for="(line) in invoice.lines.data"
                    :key="`line-${line.id}`"
                  )
                    td {{ line.description /*line.price.nickname*/ }}
                    td.text-right
                      | {{ line.amount / 100 }} {{ line.price.currency.toUpperCase() }}
                  tr
                    td MVA ({{ invoice.tax_percent }}%)
                    td.text-right
                      | {{ invoice.tax / 100 }} {{ invoice.currency.toUpperCase() }}
                  tr
                    td Totalt
                    td.text-right.font-weight-bold
                      | {{ invoice.total / 100 }} {{ invoice.currency.toUpperCase() }}
            .d-flex.mt-4.mb-2
              v-spacer
              v-btn(
                :href="invoice.invoice_pdf"
                color="secondary"
                depressed rounded
                target="_new"
              )
                | Last ned faktura
                v-icon(right) mdi-download
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'ManageInvoice',
  data: () => ({
    loading: false
  }),
  computed: mapState('Stripe', ['invoices']),
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Stripe', ['listInvoice']),
    formatTitle(invoice) {
      const total = (get(invoice, 'total', 0) / 100).toFixed(2)
      const currency = get(invoice, 'currency', '').toUpperCase()
      const invoice_number = get(invoice, 'number', 'ukjent')

      return `<span class="font-weight-bold">${total} ${currency}</span> (${invoice_number})`
    },
    formatSubtitle(invoice) {
      const created = get(invoice, 'created', null)
      if (!created) return 'ukjent'
      const formatted = dayjs(parseInt(created) * 1000).format(
        'D MMM HH:mm, YYYY'
      )

      return formatted
    },
    isPaid(invoice) {
      return get(invoice, 'paid', false)
    },
    async doListInvoice() {
      this.loading = true
      try {
        await this.listInvoice()
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    this.loading = this.invoices.length <= 0
    try {
      await this.doListInvoice()
    } catch (e) {
      this.setSnackbar({
        type: 'error',
        msg: e.message
      })
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped></style>
