<template lang="pug">
.billing
  .text-h5.font-weight-bold.primary--text.mb-6 Betaling

  template(v-if="loading")
    v-skeleton-loader.mt-10.mb-3(type="heading")
    v-skeleton-loader(type="text@3")

  template(v-else)
    v-tabs.mb-8(
      background-color="transparent"
      color="secondary"
      show-arrows
    )
      v-tab(
        to="/billing"
        exact
      ) Kunde
      v-tab(
        to="/billing/payment_method"
        exact
      ) Betalingsmåter
      v-tab(
        to="/billing/subscription"
        exact
      ) Abonnement
      v-tab(
        to="/billing/invoice"
        exact
      ) Fakturaer

    router-view
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Billing',
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapGetters('App', ['isCompanyOwner']),
    ...mapState('Stripe', ['customer'])
  },
  watch: {
    isCompanyOwner: {
      immediate: true,
      handler(v) {
        if (!v) this.$router.push('/search')
      }
    }
  },
  methods: mapActions('Stripe', [
    'readCustomer',
    'listPaymentMethod',
    'listProduct',
    'listSubscription',
    'listInvoice'
  ]),
  async mounted() {
    this.loading = !this.customer

    try {
      await Promise.all([
        this.readCustomer(),
        this.listPaymentMethod(),
        this.listProduct(),
        this.listSubscription(),
        this.listInvoice()
      ])
    } catch (e) {
      // NOP
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.billing
  .v-tabs
    position relative

    >>> .v-slide-group__content
      &::after
        content ''
        height 2px
        background rgba(0, 0, 0, .2)
        display block
        position absolute
        left 0
        right 0
        bottom 0
</style>
